/**
 * Adds highlighted spans to text based on provided start and end positions of highlights
 * @param {String} review
 * @param {Array} highlights - contains arrays of start and end positions of each highlight, [[35, 108], [200, 235]]
 */
export function highlight(review, highlights) {
  const reviewLength = review.length;
  if (reviewLength < 1) {
    return '';
  }
  const marked = Array.from(review);

  highlights.forEach(([start, end]) => {
    if (reviewLength <= start) {
      return;
    }

    const endIndex = reviewLength > end ? end : reviewLength - 1;
    marked[start] = `<span class="highlight">${marked[start]}`;
    marked[endIndex] = `${marked[endIndex]}</span>`;
  });

  return marked.join('');
}
