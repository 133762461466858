import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getWriteReviewUrl, getConsumerReviewsUrl } from 'site-modules/shared/utils/core-link-constructor';
import { InvokeAction } from 'site-modules/shared/components/inventory/invoke-action/invoke-action';
import { Link } from 'site-modules/shared/components/link/link';

const LINK_PROPS = {
  params: PropTypes.shape({
    makeSlug: PropTypes.string,
    modelSlug: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  mmsy: PropTypes.shape({
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    make: PropTypes.string,
    model: PropTypes.string,
  }),
  classes: PropTypes.string,
  target: PropTypes.string,
  actionButtonsEnabled: PropTypes.bool,
  showArrowIcon: PropTypes.bool,
};

const DEFAULT_LINK_PROPS = {
  mmsy: {},
  classes: '',
  target: '_self',
  actionButtonsEnabled: false,
  showArrowIcon: true,
};

/**
 * No reviews link (`/bmw/x3/2018/write-review.html`).
 */
export function NoReviews({ params, mmsy, classes, target, actionButtonsEnabled, showArrowIcon }) {
  const dataTrackingValue = `Write a ${mmsy.year} ${mmsy.make} ${mmsy.model} review`;

  return actionButtonsEnabled ? (
    <InvokeAction
      icon="icon-pencil"
      iconClassName="size-16 mb-0 mr-1"
      iconColor="text-gray-darker"
      primaryText="Be the first to write a review"
      primaryTextClassName="display-lg mb-0 text-primary-darker action-title"
      arrowClassName="display-lg text-primary-darker"
      dataTrackingId="write_review"
      dataTrackingValue={dataTrackingValue}
      href={getWriteReviewUrl(params)}
      classes="d-flex justify-content-between align-items-center mt-0_5 action-element fw-normal px-0_75 px-md-1"
      newTab
    />
  ) : (
    <p className="text-primary-darker heading-5 fw-normal mt-md-0">
      <Link
        to={getWriteReviewUrl(params)}
        className={classnames('text-primary-darker', classes)}
        data-tracking-id="write_review"
        data-tracking-value={dataTrackingValue}
        target={target}
      >
        Be the first to write a review {showArrowIcon && <i className="icon-arrow-right4 size-10" aria-hidden />}
      </Link>
    </p>
  );
}

NoReviews.propTypes = LINK_PROPS;
NoReviews.defaultProps = DEFAULT_LINK_PROPS;

/**
 * Write review link (`/bmw/x3/2018/write-review.html`).
 */
export function WriteReview({ params, mmsy, classes, actionButtonsEnabled, showArrowIcon, ...otherProps }) {
  return (
    <Link
      to={getWriteReviewUrl(params)}
      className={classes}
      rel="nofollow"
      data-tracking-id="write_review"
      data-tracking-value={`Write a ${mmsy.year} ${mmsy.make} ${mmsy.model} review`}
      {...otherProps}
    >
      Write a vehicle review
      {showArrowIcon && <i className="icon-arrow-right4 size-10 pl-0_25" aria-hidden />}
    </Link>
  );
}

WriteReview.propTypes = LINK_PROPS;
WriteReview.defaultProps = { ...DEFAULT_LINK_PROPS, classes: 'text-primary-darker' };

/**
 * See all consumer reviews link (`/bmw/x3/2018/consumer-reviews/`).
 */
export function SeeAllReviewsLink({ params, reviewsCount, classes, rel, target, mmsy, displayVehicle, showArrowIcon }) {
  return (
    <Link
      to={getConsumerReviewsUrl(params)}
      className={classnames('see-all-reviews', classes)}
      data-tracking-id="read_all_reviews"
      data-tracking-value="Read All Reviews"
      rel={rel}
      target={target}
    >
      {`See all ${reviewsCount} reviews${
        displayVehicle || (mmsy && mmsy.model) ? ` of the ${displayVehicle || `${mmsy.make} ${mmsy.model}`}` : ''
      }`}
      {showArrowIcon && <i className="icon-arrow-right4 size-10 pl-0_25" aria-hidden />}
    </Link>
  );
}

SeeAllReviewsLink.propTypes = {
  ...LINK_PROPS,
  displayVehicle: PropTypes.string,
  rel: PropTypes.string,
  reviewsCount: PropTypes.number,
  target: PropTypes.string,
};

SeeAllReviewsLink.defaultProps = {
  ...DEFAULT_LINK_PROPS,
  classes: 'text-primary-darker',
  displayVehicle: null,
  rel: null,
  reviewsCount: 0,
  target: '_self',
};

/**
 * See all used reviews link (`/bmw/x3/2018/consumer-reviews/`).
 */
export function SeeAllUsedReviewsLink({ params, mmsy, classes, rel, target, actionButtonsEnabled, showArrowIcon }) {
  return actionButtonsEnabled ? (
    <InvokeAction
      icon="icon-star-full"
      iconClassName="size-16 mb-0 mr-1"
      iconColor="text-gray-darker"
      primaryText={`Read ${mmsy.year} ${mmsy.make} ${mmsy.model} reviews `}
      primaryTextClassName="display-lg mb-0 text-primary-darker action-title"
      arrowClassName="display-lg text-primary-darker"
      dataTrackingId="read_all_reviews"
      dataTrackingValue="Read All Reviews"
      href={getConsumerReviewsUrl(params)}
      classes="d-flex justify-content-between align-items-center mt-0_5 action-element fw-normal px-0_75 px-md-1"
      newTab
    />
  ) : (
    <Link
      to={getConsumerReviewsUrl(params)}
      className={classnames('see-all-used-reviews', classes)}
      data-tracking-id="read_all_reviews"
      data-tracking-value="Read All Reviews"
      rel={rel}
      target={target}
    >
      {`Read ${mmsy.year} ${mmsy.make} ${mmsy.model} reviews `}
      {showArrowIcon && <i className="icon-arrow-right4 size-10" aria-hidden />}
    </Link>
  );
}

SeeAllUsedReviewsLink.propTypes = {
  ...LINK_PROPS,
  rel: PropTypes.string,
  target: PropTypes.string,
};

SeeAllUsedReviewsLink.defaultProps = {
  ...DEFAULT_LINK_PROPS,
  classes: 'text-primary-darker heading-5 fw-normal',
  rel: null,
  target: '_self',
};

/**
 * Report abuse comment link (`//help.edmunds.com/hc/en-us/requests/new?{tickedId};{ticketSubject};{ticketDescription`).
 */
export function ReportAbuseLink({ reviewTitle, reviewId, userName, classes }) {
  const ticketFormId = 'ticket_form_id=2343&amp';
  const ticketSubject = `ticket[subject]=Report review (${reviewId}) to Edmunds.com for invistigation&amp`;
  const ticketDescription = `ticket[description]=I am reporting this review ${reviewTitle} posted by ${userName} because...`;
  return (
    <Link
      to={`//help.edmunds.com/hc/en-us/requests/new?${ticketFormId};${ticketSubject};${ticketDescription}`}
      className={classnames('d-inline-block text-gray-darker', classes)}
      rel="nofollow"
    >
      Report abuse
      <i className="icon-arrow-right4 size-10 pl-0_25" aria-hidden />
    </Link>
  );
}

ReportAbuseLink.propTypes = {
  reviewId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  reviewTitle: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

ReportAbuseLink.defaultProps = {
  classes: '',
};
