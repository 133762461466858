import React from 'react';
import PropTypes from 'prop-types';

import { Review } from './review';

const MAX_TEXT_LENGTH = {
  true: 140,
  false: 390,
};

export function Reviews({ mmsy, reviews, isMobile, classes, reviewHeading, isCore5497Enabled }) {
  const reviewLength = MAX_TEXT_LENGTH[isMobile];

  return reviews.map((review, index) => (
    <Review
      key={review.id}
      isMobile={isMobile}
      mmsy={mmsy}
      review={review}
      index={index}
      classes={classes}
      reviewLength={reviewLength}
      heading={reviewHeading}
      isCore5497Enabled={isCore5497Enabled}
    />
  ));
}

Reviews.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      author: PropTypes.shape({
        authorName: PropTypes.string,
      }),
      created: PropTypes.string,
      city: PropTypes.string,
      createTime: PropTypes.string,
      createDate: PropTypes.string,
      spans: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.number,
          end: PropTypes.number,
        })
      ),
      state: PropTypes.string,
      style: PropTypes.shape({
        name: PropTypes.string,
      }),
      styleName: PropTypes.string,
      text: PropTypes.string,
      title: PropTypes.string,
      userName: PropTypes.string,
      userRating: PropTypes.number,
      vehicleRating: PropTypes.shape({
        overall: PropTypes.number,
      }),
      year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  mmsy: PropTypes.shape({
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    make: PropTypes.string,
    model: PropTypes.string,
  }).isRequired,
  isMobile: PropTypes.bool,
  classes: PropTypes.string,
  reviewHeading: PropTypes.node,
  isCore5497Enabled: PropTypes.bool,
};

Reviews.defaultProps = {
  reviews: [],
  isMobile: false,
  classes: null,
  reviewHeading: 'h3',
  isCore5497Enabled: false,
};
