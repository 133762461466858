import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from 'reactstrap/lib/Card';

import {
  IncentivesCreativeConfigDefaultProps,
  IncentivesCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import {
  CommonAdDefaultProps,
  CommonAdPropTypes,
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { INCENTIVES_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { DfpTracking } from 'client/site-modules/shared/components/native-ad/utils/dfp-tracking';

import { AdLabel } from 'client/site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { Disclaimer } from 'client/site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { addZipCode, definePlatform } from 'site-modules/shared/components/native-ad/utils/utils';
import {
  getDisplayHeadline,
  getTrackingValue,
} from 'site-modules/shared/components/native-ad/utils/offer-incentives-ad-utils';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';

import { Styles } from './incentives-site-served-ad-styles';

const DEFAULT_HEADLINE = 'See Offers %%PATTERN:zip%%';

export function IncentivesSiteServedAdUI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  creativeConfig,
  customTargeting,
  mobile,
  position,
  cardWrapper,
  horizontalLayout,
  noBorder,
}) {
  const {
    siteServedAdsUpdater,
    zipCode,
    ipZipCode,
    offersNumber,
    location,
    creativeConfigData: {
      logo,
      linkDisclaimer,
      disclaimerCopy,
      headline,
      headline2,
      linkDisplayUrl,
      cta,
      lineItemId,
      siteServedCreativeId,
      target,
      isConquest,
    },
    traffickingData: { clickTracker },
  } = creativeConfig;

  const loadTrackingListener = useCallback(
    event => {
      const trackingValue = getTrackingValue(headline, offersNumber, zipCode, ipZipCode, DEFAULT_HEADLINE, 15, 15);
      adLoadTrackingListener(event, trackingValue);
    },
    [adLoadTrackingListener, headline, ipZipCode, offersNumber, zipCode]
  );

  if (!logo || !cta) {
    return null;
  }

  const displayHeadline = getDisplayHeadline(
    headline,
    offersNumber,
    zipCode,
    ipZipCode,
    DEFAULT_HEADLINE,
    15,
    15,
    location
  );

  const ctaURL = addZipCode(clickTracker[definePlatform(mobile)], zipCode);
  const ctaTarget = target || '_blank';
  const nativeStyle = INCENTIVES_AD.NATIVE_STYLE;

  const linkDisplay = (
    <div className="text-blue-40 text-center" style={{ ...Styles.mt_0_25, ...Styles.disclaimer }}>
      {linkDisplayUrl}
    </div>
  );

  const style = noBorder ? { border: 'none' } : { overflow: 'hidden' };

  const conquestRibbonStyle = { ...Styles.conquestRibbon, marginLeft: noBorder ? '-16px' : 0 };
  const conquestRibbon = <ConquestRibbon style={conquestRibbonStyle} />;

  return (
    <Card
      className={classnames('incentives-site-served pos-r w-100', cardWrapper)}
      data-tracking-parent={nativeStyle}
      data-viewability={`${nativeStyle}-${position}`}
      innerRef={adRef}
      style={style}
    >
      <DfpTracking
        lineItemId={lineItemId}
        siteServedCreativeId={siteServedCreativeId}
        mobile={mobile}
        nativeStyle={nativeStyle}
        position={position}
        customTargeting={customTargeting}
        slotRenderEndListener={loadTrackingListener}
        siteServedAdsUpdater={siteServedAdsUpdater}
      />
      {adTrackers}
      <a
        className="site-served-ad-link no-focus text-decoration-none d-block"
        href={ctaURL}
        target={ctaTarget}
        rel="noopener noreferrer"
        onClick={adClickTrackingListener}
      >
        {!noBorder && <AdLabel />}
        <div
          className={classnames('w-100', {
            'd-flex justify-content-between align-items-center mb-0_5': noBorder,
          })}
          style={Styles.my_0_25}
        >
          {isConquest ? (
            conquestRibbon
          ) : (
            <img className={!noBorder ? 'ml-0_5' : null} style={Styles.oemLogo} alt="" src={logo} loading="lazy" />
          )}
          {noBorder && <div style={{ fontSize: '10px', textAlign: 'right' }}>Sponsored</div>}
        </div>
        <div className={classnames('d-flex justify-content-between', { 'flex-column': !horizontalLayout })}>
          <div style={{ ...(noBorder ? {} : Styles.px_1_5) }}>
            <Fragment>
              <div
                className="headline fw-bold text-gray-darkest mb-0_25"
                style={{ ...Styles.mb_0_25, ...(mobile ? Styles.headline_mobile : Styles.headline) }}
              >
                <ContentFragment>{displayHeadline}</ContentFragment>
              </div>
              <div className="small text-gray-darker" style={Styles.headline2}>
                <ContentFragment>{headline2}</ContentFragment>
              </div>
            </Fragment>
          </div>
          <div
            style={{
              ...Styles.alignSelfStart,
              ...(noBorder ? {} : Styles.px_1_5),
              ...(!horizontalLayout ? Styles.mt_0_75 : {}),
              ...(linkDisplayUrl ? Styles.mb_0_5 : Styles.withoutLinkDisplayUrl[mobile]),
            }}
            className={classnames({ 'w-100': noBorder })}
          >
            <div
              className={classnames('btn text-nowrap py-0_5 px-0_75 text-transform-none size-16', {
                'btn-blue-50': !noBorder && !isConquest,
                'btn-outline-blue-50': !noBorder && isConquest,
                'btn-outline-blue-50 w-100': noBorder,
              })}
              style={{ ...Styles.fontWeightNormal, ...Styles.py_0_75 }}
            >
              <CtaButtonText text={cta} />
            </div>
            {linkDisplay}
          </div>
        </div>
      </a>
      {disclaimerCopy && <Disclaimer linkDisclaimer={linkDisclaimer} disclaimerText={disclaimerCopy} />}
    </Card>
  );
}

IncentivesSiteServedAdUI.propTypes = {
  ...SiteServedAdPropTypes,
  customTargeting: CommonAdPropTypes.customTargeting,
  creativeConfig: IncentivesCreativeConfigPropTypes,
  cardWrapper: PropTypes.string,
  horizontalLayout: PropTypes.bool,
  noBorder: PropTypes.bool,
  vehicle: PropTypes.shape({}),
};

IncentivesSiteServedAdUI.defaultProps = {
  ...SiteServedAdDefaultProps,
  customTargeting: CommonAdDefaultProps.customTargeting,
  creativeConfig: IncentivesCreativeConfigDefaultProps,
  cardWrapper: null,
  horizontalLayout: false,
  noBorder: false,
  vehicle: {},
};

export const IncentivesSiteServedAd = siteServedAdTrackingWrapper(IncentivesSiteServedAdUI);
