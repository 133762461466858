import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'site-modules/shared/components/link/link';

import './shopping-link.scss';

export function ShoppingLink({
  label,
  description,
  creativeId,
  className,
  descriptionClassName,
  iconClassName,
  iconContainerClassName,
  iconStyles,
  labelClassName,
  arrowClassName,
  isDescriptionBelow,
  ...linkProps
}) {
  const descriptionFragment = !!description && <div className={descriptionClassName}>{description}</div>;

  return (
    <div
      className={classnames('shopping-link text-decoration-none pos-r', className)}
      data-tracking-parent={creativeId}
    >
      <div className="d-flex align-items-center">
        <i
          className={classnames(
            'link-icon d-flex align-items-center justify-content-center',
            iconClassName,
            iconContainerClassName
          )}
          style={{ ...iconStyles, flexShrink: 0 }}
          aria-hidden
        />
        <div className="pr-0_5">
          <Link {...linkProps} className={classnames('label-text no-focus', labelClassName)}>
            {label}
          </Link>
          {!isDescriptionBelow && descriptionFragment}
        </div>
        <div className="px-0 d-flex justify-content-end justify-content-md-between align-items-center ml-auto">
          <i className={arrowClassName} aria-hidden />
        </div>
      </div>
      {isDescriptionBelow && descriptionFragment}
    </div>
  );
}

ShoppingLink.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  creativeId: PropTypes.string,
  className: PropTypes.string,
  descriptionClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  iconStyles: PropTypes.objectOf(PropTypes.string),
  labelClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  isDescriptionBelow: PropTypes.bool,
};

ShoppingLink.defaultProps = {
  creativeId: null,
  description: null,
  className: 'rounded mx-0 px-1 py-0_75',
  descriptionClassName: null,
  iconClassName: null,
  iconContainerClassName: 'align-self-start mr-0_75 size-16 text-blue-50',
  iconStyles: { height: '36px', width: '36px' },
  labelClassName: 'text-gray-darkest fw-bold',
  arrowClassName: 'icon-arrow-right3 text-primary-darker medium',
  isDescriptionBelow: false,
};
