import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './conquest-ribbon.scss';

export function ConquestRibbon({ style, classes, isLarge, text, type }) {
  return (
    <div
      className={classnames('ribbon-flag d-inline-block py-0', classes, {
        'size-10 px-0_5': !isLarge,
        'size-12 pl-2 pr-0_5': isLarge,
        'conquest-ribbon text-primary-darker': type === 'conquest',
        'did-you-know text-cool-gray-30': type === 'did-you-know',
      })}
      style={style}
    >
      {text}
    </div>
  );
}

ConquestRibbon.propTypes = {
  style: PropTypes.shape({}),
  classes: PropTypes.string,
  isLarge: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
};

ConquestRibbon.defaultProps = {
  style: null,
  classes: '',
  isLarge: false,
  text: 'Also Consider',
  type: 'conquest',
};
