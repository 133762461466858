import PropTypes from 'prop-types';
import { get } from 'lodash';

export const CorePageParams = PropTypes.shape({
  make: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  submodel: PropTypes.string,
  isUsed: PropTypes.bool,
  isNewUsed: PropTypes.bool,
});

export function getParamsFromVehicle(vehicle) {
  return vehicle
    ? { make: vehicle.make.slug, model: vehicle.model.slug, year: vehicle.year, submodel: vehicle.submodels.slug }
    : null;
}

export function getParamsFromContextVehicle(contextVehicle) {
  return contextVehicle
    ? {
        make: get(contextVehicle, 'make.niceName'),
        model: get(contextVehicle, 'model.niceName'),
        submodel: get(contextVehicle, 'subModel.niceName'),
        year: get(contextVehicle, 'modelYear.year'),
      }
    : {};
}
