import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isFunction } from 'lodash';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { getIntersectionObserver } from 'client/utils/intersection-observer';

import './animated-sticky.scss';

export const STICKY_STATUS = {
  STICKY: 'sticky',
  RELEASED: 'released',
};

/**
 * This Sticky module solves 2 CLS issues, the removal of the children element from the
 * flow of the document and the sudden appearance of a fixed position nav which registers
 * a layout shift. Here we are using a css transform which does NOT count towards CLS.
 */
export class AnimatedSticky extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
    onStateChange: PropTypes.func,
    className: PropTypes.string,
    stickyClassName: PropTypes.string,
    identifierClass: PropTypes.string,
    stickyFragment: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    rootMargin: PropTypes.string,
    intersectionRef: PropTypes.shape(),
    disableSticky: PropTypes.bool, // handy if you only need sticky for desktop but not mobile
    hideSticky: PropTypes.bool,
  };
  static defaultProps = {
    children: null,
    onStateChange: null,
    className: null,
    stickyClassName: null,
    identifierClass: null,
    stickyFragment: null,
    rootMargin: `0px 0px`,
    intersectionRef: null,
    disableSticky: false,
    hideSticky: false,
  };

  constructor(props) {
    super(props);

    if (!this.props.disableSticky) {
      const IntersectionObserver = getIntersectionObserver();

      if (IntersectionObserver) {
        this.observer = new IntersectionObserver(this.checkIntersection, { rootMargin: this.props.rootMargin });
      }
    }

    this.intersectionRef = this.props.intersectionRef || React.createRef();
    this.state = {
      sticky: null,
      hidden: null, // yes, this is also !sticky. it is here to suppress animation on page load
    };
  }

  componentDidMount() {
    if (this.observer && this.intersectionRef.current) {
      this.observer.observe(this.intersectionRef.current);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  checkIntersection = changes => {
    changes.forEach(change => {
      const sticky = !change.isIntersecting && change.boundingClientRect.bottom < 0;
      if (sticky !== this.state.sticky && this.props.onStateChange) {
        this.props.onStateChange({ status: sticky ? STICKY_STATUS.STICKY : STICKY_STATUS.RELEASED });
      }

      this.setState({ sticky, hidden: !sticky });
    });
  };

  render() {
    const { children, stickyFragment, className, stickyClassName, identifierClass, hideSticky } = this.props;
    const { sticky, hidden } = this.state;

    return (
      <div className={classnames('animated-sticky w-100', identifierClass, { sticky, hidden })}>
        {!!children && (
          <div className={classnames('page-content', className)} ref={this.intersectionRef}>
            {children}
          </div>
        )}
        {!hideSticky && (
          <div className="sticky-content w-100 bg-white">
            <Container>
              <Row className="sticky-content-aria" aria-hidden={hidden}>
                <Col className={stickyClassName}>
                  {isFunction(stickyFragment) ? stickyFragment(sticky) : stickyFragment || children}
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    );
  }
}
